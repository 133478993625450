import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://d02cb79acaa2ab14c19951319e37b3bd@o4507351505698816.ingest.us.sentry.io/4507996909010944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/communitylifeline.org/, /^https:\/\/www.communitylifeline.org/,/^https:\/\/preview.communitylifeline.org/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
