module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"021947a6aa08e6ea22a88a51d8afacb0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-cookieconsent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"guiOptions":{"consentModal":{"layout":"bar","position":"bottom","equalWeightButtons":true,"flipButtons":true},"preferencesModal":{"layout":"bar","position":"right","equalWeightButtons":true,"flipButtons":false}},"categories":{"necessary":{"readOnly":true},"marketing":{}},"language":{"default":"en","autoDetect":"browser","translations":{"en":{"consentModal":{"title":"Accept Cookies?","description":"Your privacy is important. We use technologies such as cookies to enable essential site functionality, as well as for analytics, personalization, and targeted advertising.","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","showPreferencesBtn":"Manage preferences","footer":"<a href=\"/cookiepolicy\">Cookie & Privacy Policy</a>"},"preferencesModal":{"title":"Consent Preferences Center","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","savePreferencesBtn":"Save preferences","closeIconLabel":"Close modal","serviceCounterLabel":"Service|Services","sections":[{"title":"Cookie Usage","description":"We use cookies to help us measure the performance of our application, to record your information when you submit forms, and if approved, to re-target advertisements relevant to donors."},{"title":"Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>","description":"Only the minimal set of cookies, necessary to operate the website without identifying you personally","linkedCategory":"necessary"},{"title":"Advertisement Cookies","description":"Advertisement cookies that may serve to identify your browser and device for the purpose of targeting advertisements that solicit donations","linkedCategory":"marketing"}]}}}}},"enableForAllEnvironments":true,"googleGtagPluginConfig":{"trackingIds":["G-3FW9EQ1V3J"],"gtagConfig":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3FW9EQ1V3J"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
